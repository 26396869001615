<template>
    <div class="background flex min-h-screen flex-col items-center pt-6 sm:justify-center sm:pt-0">
        <div />

        <div class="xs:w-full mt-6 overflow-hidden bg-white px-6 py-4 shadow-md sm:max-w-md sm:rounded-lg lg:w-96">
            <div>
                <div class="my-5 flex justify-center">
                    <slot name="logo" />
                </div>
            </div>

            <slot />
        </div>
    </div>
</template>

<style scoped>
.background {
    background-image: url("../../images/home-hero-bg.jpg");
    background-size: cover;
    background-position: center center;
}
</style>
